$input-height: 54px;

.btn-borrower{
  border-radius: 100px;
}

.form-div{
  margin: 65px 0
}

.close-modal-btn{
  svg{
    fill: $gray-700;
    &:hover{
      fill: $primary;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 35px;
}

.form-label {
  font-size: $font-size-sm;
  margin-bottom: 6px;
  order: 0;
  color: $gray-700;
}

.label-terms{
  color: $gray-400;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  button{
    color: $gray-900;
    background-color: transparent;
    border: none;
    padding: 0;
    &:hover{
      text-decoration: underline;
    }
  }

}

.wide-label{
  width: 390px;
}

.input-group{
  min-height: $input-height;
  border-radius: $radius-lg;
  order: 2;

  .input-group-text{
    color: $gray-400;
    background-color: transparent;
    border: 1px solid $gray-300;
  }
  .disabled{
    background-color: $gray-50;
    color: $gray-400;
  }

  .form-control{
    &:active, &:focus, &:hover {
      box-shadow: 0 0 0 1px $primary;

      // &~.form-label {
      //   color: $primary;
      // }
    }
    &:disabled {
      box-shadow: none;
      background-color: transparent;
      border: 1px solid $gray-300;

      .file-div__actions {
        .btn {
          opacity: 1 !important;
        }
      }
    }
    &::placeholder {
      color: $gray-400;
    }
  }
}

.delete-file-btn:disabled{
  cursor: not-allowed;

  .delete-file-btn-disabled:hover{
  cursor: not-allowed !important;
    color: $gray-800 !important;
    svg path{
      stroke-width: 1.7;
      stroke: $gray-800 !important;
  }
  }
}

.delete-file-btn div:hover{
  color: $error !important;
  svg path{
      stroke-width: 1.7;
      stroke: $error !important;
  }
}
.delete-file-btn div:focus, .download-file-btn div:focus  {
  outline: 0 !important;
}

.download-file-btn div:hover{
  color: $primary !important;
  svg path{
    stroke-width: 1.7;
    stroke: $primary;
  }
  
}

.silta-form {
  div.silta-form-check {
    margin-bottom: 28px;
    padding-left: 0;

    .form-check {
      padding-left: 0 !important;
      padding-top: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    .form-check-input {
      height: 20px;
      width: 20px;
      margin: 0 !important;
      left: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .form-check-label {
      position: relative;
      bottom: 4px;
      margin-left: 12px !important;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.form-separator {
  width: 100%;
  height: 1px;
  background-color: $gray-200;
  margin-top: 95px;
  margin-bottom: 70px;

}

.form-text {
  max-width: 550px;
}


.form-text-wide {

  &--sm {
    color: $gray-400;
    font-size: $font-size-sm;
  }
}

.form-textarea {
  max-width: 100% !important;
}

textarea {
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-link {
  &__hint {
    color: $gray-500;
    font-size: $font-size-xs;
  }
}

.form-accepted-files {
  font-size: $font-size-xs;
  color: $gray-500;
}

.custom-control-label{
  color: black;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.form-control {
  min-height: $input-height;
  border: 1px solid $gray-300;
  border-radius: $radius-lg;
  order: 2;
  padding-left: 19px;
  padding-right: 19px;
  color: $gray-900;

  &::placeholder {
    color: $gray-400;
  }

  &:active, &:focus {
    box-shadow: 0 0 0 1px $primary;
    color: $gray-900;

    &~.form-label {
      color: $primary;
    }
    &:hover{
    box-shadow: 0 0 0 1px $primary;

     &~.form-label {
      color: $primary;
    }
  }

  }
  &:hover{
    box-shadow: 0 0 0 0.5px $gray-500;
    &~.form-label {
      color: black;
    }
  }

  &:disabled {
      background-color: $gray-50;
      color: $gray-400 !important;
    &:active, &:focus, &:hover{
      box-shadow: none;
    }

    // &~.form-label {
    //   &:after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     right: -40px;
    //     width: 16px;
    //     height: 16px;
    //     top: 40px;
    //     transform: translate(-50%, 50%);
    //     background: url('../assets/images/disabled.svg') no-repeat center;
    //     background-size: cover;
    //   }
    // }
  }
}

.form-hint {
  color: $gray-500;
  font-size: $font-size-xs;
  margin-top: 6px;
  order: 4;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 6px));
}

.checkbox-error{
  font-size: $font-size-xs;
  color: $error;
  margin-top: 0px;
  margin-bottom: 24px;

  &~.custom-control-label {
    &::after{
      border: 1px solid red
    }
  }
}
.error-checkbox{
  .custom-control-label::after {
    border: 1px solid $error;
    border-radius: 5px;
    background-color: $error-25
  }
}

.error-radio{
  .custom-control-label::after {
    border: 1px solid $error;
    border-radius: 8px;
    background-color: $error-25
  }
}

.error-message-checkbox{
  font-size: 12px;
  margin-top: 6px;
  color: $error;
}

.error-message {
  @extend .form-hint;
  color: $error;
  order: 3;
  width: 100%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 8px;
    width: 20px;
    height: 20px;
    top: -34px;
    background-color: white !important;
    transform: translate(-50%, -50%);
    background: url('../assets/images/error.svg') no-repeat center;
    background-size: cover;
  }
  &~.input-group .form-control{
    box-shadow: 0 0 0 2px $error !important;
  }

  &~.container-group {
    box-shadow: 0 0 0 2px $error !important;
  }

  &~.css-b62m3t-container{
    box-shadow: 0 0 0 2px $error !important;
    border-radius: 8px;
  }

  &~.form-control {
    box-shadow: 0 0 0 2px $error !important;
  }

  &~.dropzone{
    box-shadow: 0 0 0 1px $error-300 !important;
    background-color: white;
    &:hover{
    box-shadow: 0 0 0 1px $error !important;
    background-color: $error-25;
    }
  }

  &~.form-hint {
    display: none !important;
  }

  &~.form-hint {
    display: none !important;
  }

}

.signup-btn{
  margin: 33px 0 22px 0;
}

.login-back{
  // margin-top: 22px;
  a{
    color: $gray-900;
    font-size: $font-size-xs;
  }
  svg{
    width: 18px;
    height: 18px;
    color: $gray-900;
    margin-right: 4px;
  }
}

.error-message-login{
  color: $error-700;
  order: 3;
  width: 100%;
  font-size: $font-size-sm;
  padding-left: 45px;
  padding-right: 20px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  margin-bottom: 28px;
  p{
    color: $error-600;
    margin-top: 6px;
    margin-bottom: 2px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 25px;
    width: 20px;
    height: 20px;
    top: 25px;
    transform: translate(-50%, -50%);
    background: url('../assets/images/error.svg') no-repeat center;
    background-size: cover;
  }
}

.alert-danger{
  background-color: $error-25;
  border-color: $error-300;
}

// CHECKBOX INPUT

.custom-control-label::before{
  border: 1px solid $gray-300;
}


.disabled{
  .custom-radio{
    border: 1px solid $gray-300 !important;
    background-color: $gray-50 !important;
    color: $gray-400
  }
  .no-bg{
    background-color: transparent !important;
    border: none !important;
    margin-bottom: 10px !important;
   }
}

.form-radio{
  background-color: rgba(216, 208, 233, 0.2);
  padding: 16px 30px 0px 26px;
  border: 1px solid $primary;
  border-radius: $radius-lg;
  width: fit-content;
  margin-top: 0px !important;
}

.no-bg{
 background-color: transparent;
 border: none;
 padding-right: 0;
 margin-bottom: 0px !important;
 margin-top: 0px !important;
 padding-left: 0 !important;
 font-size: $font-size-sm;
 display: flex;
 align-items: baseline;
  &-sigunp{
    background-color: transparent;
    border: none;
    padding-right: 0;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-size: $font-size-sm;
    display: flex;
    align-items: baseline;
  }
}

input[type=radio]:checked{
  color: $primary;
  background-color: $primary-50;
  background-image: url('../assets/images/check-dot.svg')!important ;
  background-size: auto;
  width: 18px;
  height: 16px;
  &:active, &:focus{
    box-shadow: none !important;
  }
 }

 input[type=checkbox]:checked{
  &:active, &:focus{
    box-shadow: none !important;
  }
 }


#tooltip-hint {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  margin-left: 5px;

  .tooltip-inner {
    font-size: $font-size-xs;
    text-align: left;
    max-width: 320px;
    padding: 10px 12px;
  }
}

.hint-icon{
  width: 15px;
  position: relative;
  bottom: -8px;

  &:hover {
    cursor: pointer;
  }
}

// SELECT INPUT

.css-tlfecz-indicatorContainer{
  color: #667085;
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator,
.selectStyle__indicator-separator,
.selectCurency__indicator-separator{
  display: none !important;
}

.selectStyle__control{
  min-height: $input-height !important;
  border-radius: $radius-lg !important;
  // margin-bottom: 40px;

  .selectStyle__value-container{
    padding: 6px 12px !important;
  }

  &:hover{
    box-shadow: 0 0 0 0.5px $gray-500;
    &~.form-label {
      color: black;
    }
  }
}

// .container-group{
//   &:active, &:focus {
//     box-shadow: 0 0 0 1px $primary !important;

//     &~.form-label {
//       color: $primary !important;
//     }
//   }
// }

.selectStyle__menu{
  z-index: 9999 !important;
}

.selectStyle__option--is-selected{
  background: url('../assets/images/check.svg') no-repeat right !important;
  background-size: initial !important;
  background-position: 96% 50% !important;
}

.selectStyle--is-disabled{
 .css-1insrsq-control{
  border: 1px solid $gray-300 !important;
  background-color: $gray-50 !important;
  color: $gray-400 !important;
}
}

.css-9gakcf-option{
  background-color: $primary-25 !important;
  color: $primary !important;
}

.selectStyle__option:hover{
  background-color: $primary-25 !important;
  color: $primary !important;
}


 .css-1pahdxg-control{
   box-shadow: 0 0 0 1.5px $primary !important;
   border: none !important;
   &:hover{
     border: none !important;
   }
 }

 .css-1n7v3ny-option{
   background-color: white !important;
  }

  .weblink-input{
     &:hover{
       box-shadow: 0 0 0 0.5px $gray-500 !important;
       &~.form-label {
         color: black;
       }
    }

     &:active, &:focus {
       box-shadow: 0 0 0 1px $primary !important;

       &~.form-label {
         color: $primary !important;
       }
   }
   &:disabled{
       background-color: $gray-50 !important;
   }
}


// CURRENCY INPUT
.currency-input {
  .selectCurrency__control, .selectCurency__control, .css-1pahdxg-control, .css-1s2u09g-control, .css-3iigni-container, .css-1insrsq-control{
    border: none !important ;
    min-height: 53px !important;
    width: 90px;
    height: 100% !important;
    border-radius: $radius-lg;
  }
}

.selectCurency__option:hover{
  background-color: $primary-25 !important;
  color: $primary !important;
}

 .container-group{
   min-height: $input-height;
   border: 1px solid $gray-300;
   border-radius: $radius-lg;
   background-color: white;

   &:active, &:focus {
    box-shadow: 0 0 0 1px $primary;
   }

   &:hover{
    box-shadow: 0 0 0 0.5px $gray-500;
    &~.form-label {
      color: black;
    }
  }

   .input-group-text,
   #currencyInput
   {
    padding-left: -10px !important;
     &:disabled {
       background-color: $gray-50 !important;
       color: $gray-400 !important;
     }
     &::placeholder{
      color: $gray-400;
     }
    }

   .input-group-prepend .input-group-text{
    background-color: transparent !important;
    padding-left: 20px ;
   }
   .css-3iigni-container,
   .css-1insrsq-control{
    background-color: $gray-50;
    color: $gray-400;
   }
   .input-group-text{
    border-radius: $radius-lg;
    border: none !important;
    order: 1;
    }

    .form-control, .css-1d8n9bt{
     padding-left: 0 !important;

    //   &:hover{
    //     box-shadow: 0 0 0 0.5px $gray-500;
    //     &~.form-label {
    //       color: black;
    //     }
    //  }

    //   &:active, &:focus {
    //     box-shadow: 0 0 0 1px $primary !important;

    //     &~.form-label {
    //       color: $primary !important;
    //     }
    }

   #currencyInput{
     border: none !important;
     order: 2;
     &:active, &:focus, &:hover{
      box-shadow: none;
     }
    }
    .css-b62m3t-container, .selectCurency--is-disabled {
      order: 5;
      .css-1pahdxg-control{
        box-shadow: none !important;
      }
      .css-1insrsq-control{
        background-color: transparent;
      }
    }
    .css-tj5bde-Svg{
      fill: #667085;
    }
    .selectCurency__control{
      width: 85px !important;
      padding-right: 10px;
      @media (min-width: 1400px) {
        width: 95px !important;
      }
    }
    .css-319lph-ValueContainer{
      left: 8px;
      padding: 0;
    }
 }

 .disabled{
  &:active, &:focus, &:hover{
    box-shadow: none !important;
  }
  background-color: $gray-50 !important;
  color: $gray-400 !important;
 }

 .container-group .form-control{
    border: none;

    &:active, &:focus, &:hover{
      box-shadow: none;
      border: none;
     }

     &:disabled{
      background-color: $gray-50;
      color: $gray-400 !important;
      border: none;
    }
  }

  #inputGroupPrepend{
    background-color: $gray-50;
    padding: 0 20px ;
    .disabled{
      &:active, &:focus, &:hover{
        box-shadow: none !important;
      }
      background-color: $gray-50 !important;
      color: $gray-400 !important;
     }
   }

// PHONE INPUT

.selectPhone__option:hover{
  background-color: $primary-25 !important;
  color: $primary !important;
}

.input-phone{

  .css-b62m3t-container{
    order: 2 !important
  }

  .form-control{
    order: 6;
    border: none;
    padding-left: 5px !important ;
    &:active, &:focus, &:hover{
      box-shadow: none;
      border: none;
     }
     &::placeholder{
      color: $gray-400
     }
     &:disabled{
      background-color: $gray-50;
      color: $gray-400 !important;
      border: none;
    }
  }

    .selectPhone__value-container{
     padding-left: 1rem !important;
     padding-right: 0 !important;
    }

    .selectPhone__control{
      border-radius: $radius-lg;
      height: 100%;
      border: none;
    }

    // .prepend-phone{
    //   order: 2;
    //   .input-group-text{
    //     padding-right: 0;
    //     color: $dark;
    //   }
    //   .disabled{
    //     color: $gray-400 !important;
    //   }
    // }
    // &:disabled{
    //   background-color: $gray-50;
    //   color: $gray-400 !important;
    //   border: none;
    //   box-shadow: none;
    // }

}

.input-group-prepend {
  .input-group-text {
    border-top-left-radius: $radius-lg !important;
    border-bottom-left-radius: $radius-lg !important;
    background: #F9FAFB !important;
    min-height: 54px;
    width: 110%;
  }
}
.prepend-phone.input-group-prepend .input-group-text{
  background: white !important;
}

.silta-form--disabled {
  .dropzone {
    pointer-events: none !important;
    background-color: $gray-50;
    .upload-text, p, .form-accepted-files{
      color: $gray-400;
    }
  }
}

// FILE INPUT
 .dropzone {
   border: 1px solid $gray-300;
   padding: 16px 24px;
   border-radius: $radius-lg;
   color: $gray-500;

   .upload-icon{
    width: 46px;
    height: 46px;
    background: url('../assets/images/icons/upload.svg') no-repeat center !important;
    margin: 0 auto 10px auto;
   }


  &:hover {
    cursor: pointer;
    border: 1px solid $primary-100;
    background-color: $primary-25;
    .upload-text-box, .form-accepted-files{
      color: $primary;
    }

    .upload-icon{
      color: $primary;
      width: 46px;
      height: 46px;
      background: url('../assets/images/icons/upload-violet.svg') no-repeat center !important;
    }
  }

   p{
     font-size: $font-size-sm;
     margin-bottom: 0 !important;
   }

   .upload-text{
     color: $primary;
   }
 }

.file-div {
  margin-top: 20px;
  border: 1px solid $gray-200;
  border-radius: $radius-lg;
  position: relative;

  &__completed {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__padding {
    padding: 16px;
  }

  &__actions {
    border-top: 1px solid $gray-200;
    padding: 8px 10px;

    button {
      border: none !important;
      opacity: 1 !important;
      pointer-events: all !important;

      div {
        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }
}

.file-div-error{
  margin-top: 20px;
  border: 1px solid $error-300;
  background-color: $error-25;
  padding: 20px;
  border-radius: $radius-lg;

  &--sm{
  padding: 16px !important;

  .text-path-error{
    margin-bottom: 5px;
  }
  .close-btn{
    color: $error-700;
    width: 20px;
    height: 20px;
    &:hover{
      cursor: pointer;
    }
  }
  }
}

  .upload-img{
    width: 50px;
  }
  .delete-btn{
    width: 36px;

    &:hover {
      cursor: pointer;
    }
  }

  .text-path, .text-percentage{
    margin-top: 0;
    margin-bottom: 0;
    color: $gray-700;
  }
  .text-size{
    color: $gray-500;
    margin-bottom: .5rem;
  }


.progress{
  background-color: $primary-50;
  border-radius: $radius-lg;
  flex: 1;
  height: 8px;
}
.progress-bar{
 border-radius: $radius-lg;
}

.try-again, .text-path-error{
  color: $error-700;
  font-weight: 500;
  margin-bottom: 0;
}
.text-size-error{
  color: $error-600;
  margin-bottom: 10px;
}

.subtl{
  color: $gray-500;
  font-size: $font-size-sm;
}

.add-profile{
  border: none;
  background-color: transparent;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  color: $gray-500 !important;
  font-size: $font-size-sm;

  span{
    margin-left: 10px !important;
  }

  &:hover, &:active{
    color: $primary !important;
  }
}

.btn-submit-application:disabled{
    background-color: $gray-300 !important;
    border: 1px solid $gray-300;
}