.loan-pools {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 63px;

  &__header {
    font-size: 14px;
    margin-bottom: 11px;
  }

  &__pool {
    margin-bottom: 30px;
    background: white;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
    padding: 26px 32px 42px 32px;
    min-width: 415px;
    display: flex;
    flex-direction: column;
    color: black;

    &__button{
      color: white;
      &:hover{
        color: white;
        text-decoration: none;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &__icon{
      width: 15px;
      height: fit-content;
      margin-left: 0;
      margin-right: 7px;
    }

    &__status {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      margin-bottom: 22px;
      padding: 0 8px 0 8px;
      border-radius: 16px;

      &__indicator {
        width: 6px;
        height: 6px;
        margin-right: 6px;
        border-radius: 50%;
      }

      &--open {
        background: #EFF8FF;
        color: #175CD3;
        font-size: $font-size-xs;

        .loan-pools__pool__status__indicator {
          background-color: #2E90FA;
        }
      }

      &--locked {
        background: #ECFDF3;
        color: #027A48;
        font-size: $font-size-xs;

        .loan-pools__pool__status__indicator {
          background-color: #12B76A;
        }
      }

      &--repayment {
        background: #FFFAEB;
        color: #B54708;
        font-size: $font-size-xs;

        .loan-pools__pool__status__indicator {
          background-color: #F79009;
        }
      }
    }
    .img-tokens{
      width: 25px;
      border-radius: 1000px;
      margin-right: 10px;
      margin-left: -18px;
      border: 1px solid white; 
    }

    &__balance {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 22px;

      span {
        color: #98A2B3;
        font-weight: 400;
        margin-right: 12px;
      }
    }

    &__time {
      color: #98A2B3;
      font-size: 14px;

      &__left {
        color: #1D2939;

        span {
          color: black;
          font-weight: 500;
        }
      }
    }

    &__description {
      font-size: 14px;
      margin-top: 24px;
    }

    &__title {
      text-transform: uppercase;
      font-weight: 500;
    }

    &__subtitle {
      color: #5720CD;
      font-weight: 500;
      font-size: 12px;
      margin-right: 20px;
    }

    &__logo {
      width: 32px;
      height: 32px;
      object-fit: cover;
      margin-right: 12px;
    }

    &__separator {
      border-top: 1px solid #E4E7EC;
      margin: 25px 0;

      &--large {
        margin: 35px 0;
      }
    }

    &__allocation {
      position: relative;
      background: #D0D5DD;
      border-radius: 100px;
      width: 100%;
      height: 10px;
      margin-top: 24px;
      margin-bottom: 11px;

      &__bar {
        position: absolute;
        left: 0;
        width: 50%;
        height: 100%;
        background: #5720CC;
        border-radius: 100px;
      }

      &__value {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -100%);
        font-size: 12px;
      }

      &__label {
        font-size: 12px;
        color: #1D2939;

        &--active {
          color: #5720CC;
        }
      }

      &__data {
        width: 29%;
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 40px;

        &__title {
          color: #98A2B3;
          font-size: 14px;
        }

        &__value {
          font-weight: 500;
          font-size: 14px !important;
          color: black;
        }
      }
    }

    &__rewards {
      font-size: 14px;
      color: #5720CD;
    }

    &__calculator {

      &__title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 7px;
      }

      &__subtitle {
        color: #667085;
        font-size: 14px;
      }

      &__cont {
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
        margin-top: 40px;
      }

      &__label {
        text-align: right;
        font-size: 14px;
        color: #667085;
        margin-bottom: 3px;
      }

      &__input {
        position: relative;
        margin-bottom: 13px;

        input {
          width: 100%;
          height: 43px;
          border: 1px solid #D0D5DD;
          border-radius: 4px;
          padding: 0 8px
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }

        &__right {
          position: absolute;
          right: 8px;
          font-weight: 500;
          top: 12px;
          font-size: 14px;
        }
      }

      &__result {
        margin-top: 13px;
        text-align: center;

        &__value {
          font-weight: 500;
        }

        &__text {
          color: #98A2B3;
          font-size: 14px;
        }
      }
    }

    &__rari {
      text-align: center;
      color: #5720CD;
      font-size: 14px;
      margin-top: 50px;
    }

    &__data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: $font-size-sm;
      font-weight: 500;

      &__title {
        color: #98A2B3;
      }

      &__value {
        color: black;
      }
    }
  }
}