$primary: #5720cd;
$primary-25: #FBF9FE;
$primary-50: #F5F1FD;
$primary-100: #E0D4F8;
$primary-400: #A27FEB;
$primary-500: #7A5AF8;
$secondary: #341478;
$dark: #1e1e1e;
$light: #F5F1FD;
$gray-25: #FCFCFD;
$gray-50: #F9FAFB;
$gray-100: #F2F4F7;
$gray-200: #E4E7EC;
$gray-300: #D0D5DD;
$gray-400: #98A2B3;
$gray-500: #667085;
$gray-700: #344054;
$error-25: #FFFBFA;
$error-50: #FEF3F2;
$error-300: #FDA29B;
$error: #F04438;
$error-600: #D92D20;
$error-700: #B42318;
$success-25: #F6FEF9;
$success-50: #ECFDF3;
$success-600: #039855;
$success-700: #027A48;



