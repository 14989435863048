// .silta-page:not(.silta-page--with-sidebar) {
//   .project-list {
//     align-items: center;
//     justify-content: center;
//   }
// }

.project-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  &__project {
    width: 100%;
    max-width: 900px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    padding: 29px 60px 26px 26px;
    border-radius: 4px;
    margin-bottom: 14px;
    transition: $default-transition;
    color: $dark;
    background-color: white;

    &:hover {
      // border-color: $primary !important;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
      cursor: pointer;
      text-decoration: none;
      color: $dark;
    }

    &__logo {
      object-fit: cover;
      width: 48px;
      height: 48px;
      margin-right: 30px;
      border-radius: $default-radius;
    }

    &__title {
      font-size: $font-size-xl;
      font-weight: 600;
    }

    &__location {
      font-size: $font-size-lg;
      margin-right: 10px;
      line-height: 16px;
      margin-top: 3px;
    }
    &__tag-container{
      width: 150px;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      left: 40px;
    }

    &__tag {
      margin-right: 49px;
      &-bundled{
        margin-top: 0 !important;
      }
    }

    &__info {
      margin-top: 42px;
      max-width: 93.7%;

      &__cell {
        max-width: 135px;
        font-size: $font-size-sm;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &__label {
        color: $gray-400;
        font-size: $font-size-xs;
        font-weight: 400;
      }
    }
  }
}

.financiers-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 40px auto 0 auto;
  
  &__project {
    width: 48%;
    height: 100px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 14px;
    transition: $default-transition;
    color: $dark;
    background-color: white;

    &:hover {
      // box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
      // // cursor: pointer;
      // text-decoration: none;
      // color: $dark;
    }

    &__logo {
      object-fit: cover;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: $default-radius;
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    &__tag-container{
      width: 100%;
      border-top: 1px solid $gray-200;
      margin-top: 16px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__tag {
      margin-right: 49px;
        font-size: $font-size-xs;
        font-weight: 400;
        background: #ECFDF3;
        color: #027A48;
        border-radius: 16px;
        display: flex;
        align-items: center;
        height: 22px;
        padding: 0 8px;
        width: fit-content;
        text-transform: uppercase;

        &__indicator {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 7px;
          background: #12B76A;
        }
    }
    &__link{
      font-size: 14px;
      svg{
        width: 26px;
        height: 26px;
        margin-left: 4px;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.tag-verified{
    margin-right: 49px;
      font-size: $font-size-xs;
      font-weight: 400;
      background: #ECFDF3;
      color: #027A48;
      border-radius: 16px;
      display: flex;
      align-items: center;
      height: 22px;
      padding: 0 8px;
      width: fit-content;
      text-transform: uppercase;

      &__indicator {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 7px;
        background: #12B76A;
      }
}


.tag-prequalified{
  text-transform: uppercase;
    color: #107569;
    background-color: #F0FDF9;
    padding: 2px 10px;
    border-radius: 16px;
    font-size: $font-size-xs;
    height: 22px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // margin-right: 20px;
    &::before{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 7px;
      background: #107569;
      content: '';
    }
    &--wide{
    width: 142px !important;
  }
}

.tag-scored{
  text-transform: uppercase;
  color: $primary;
  background: $light;
  padding: 2px 10px;
  border-radius: 16px;
  font-size: $font-size-xs;
  height: 22px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &::before{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 7px;
    background: $primary-500;
    content: '';
  }
  &--wide{
    width: 142px !important;
  }
}

.tag-funded{
  text-transform: uppercase;
    color: $success-25;
    background: $success-600;
    padding: 2px 10px;
    border-radius: 16px;
    font-size: $font-size-xs;
    height: 22px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    &::before{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 7px;
      background: $success-25;
      content: '';
    }
    &--wide{
    width: 142px !important;
  }
}

.tag-financing{
    text-transform: uppercase;
    color: white;
    background: #175CD3;
    padding: 2px 10px;
    border-radius: 16px;
    font-size: $font-size-xs;
    height: 22px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: -28px;
    
    &::before{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 7px;
      background: white;
      content: '';
    }
    &--wide{
    width: 142px !important;
  }
}

.tag-bundled{
  text-transform: uppercase;
  color: $gray-700;
  background: $gray-100;
  padding: 2px 10px;
  border-radius: 16px;
  font-size: $font-size-xs;
  height: 22px;
  margin-top: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .tooltip-help{
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
  }
}

.prequal-banner{
  background-color: white;
  max-width: 900px;
  margin: 0 auto;
  height: 90px;
  padding: 10px 157px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $gray-500;
  font-size: 16px;
  margin-bottom: 19px;
  a{
    margin-left: 12px;
    svg{
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}