// TODO: make oher types of notifications once designed

.notification {
  width: 100%;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #B42318;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;

  svg {
    position: relative;
    top: 4px;
  }

  &__title {
    font-weight: 500;
    color: #B42318;
    margin-left: 12px;
    margin-bottom: 2px;
  }

  &__text {
    line-height: 20px;
    color: #D92D20;
    margin-left: 12px;
  }

  &--error {
    border: 1px solid #FDA29B;
    background: #FFFBFA;
  }
}