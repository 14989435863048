.application-modal{
  .confirm-delete.btn.btn-primary{
    background-color: $error-600 !important;
    border-color: $error-600 !important;
    &:hover{
      background-color: $error-700 !important;
      border-color: $error-700 !important;
    }
  } 
  .delete-text{
    font-size: 15px !important;
  }
  .cancel-delete.btn.btn-primary{
    background-color: transparent !important;
    border: 1px solid $gray-300 !important;
    color: $gray-700 !important;
    &:hover{
      background-color: $gray-50 !important;
    }
  }
} 

.loan-application-page {
  .button-nav .active {
    color: black;
  }
  .loan-applications {
    max-width: 900px;
    margin: 0 auto;
  }

  &__welcome{
    font-weight: 600;
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  &__user-notification{
    color: $error;
    font-size: 14px;
    max-width: 100%;
    padding: 20px;
    border: 1px solid $error;
    background-color: $error-50;
    border-radius: 20px;
    margin-bottom: 20px;
    svg{
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }

  .application {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border: 1px solid $gray-200;
    border-radius: $radius-lg;
    padding: 16px;
    transition: $default-transition;
    width: 272px;
    min-height: 153px;
    color: $dark;
    margin-bottom: 25px;
    margin-right: 28px;

    &--empty {
      width: 100%;
      padding: 55px 35px;

      .application__title {
        font-size: $font-size-xl;
        font-weight: 600;
      }

    }

    &--new {
      .application__btn {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 15px;
      }

      .application__title {
        margin: 0 auto;
      }
    }
    &__btns-container{
      margin-top: 32px

    }


    &__dropdown-dots{
      width: 36px;
      height: 36px;
      &:hover{
        background-color: $gray-100;
        border-radius: 6px;
      }
      &:active,&:focus{
        border: none;
        box-shadow: none;
      }
      .dropdown-toggle{
        display: flex;
        align-items: center;
        justify-content: center;

        &.btn{
          color: transparent !important;
          padding-right: 0;
          margin-right: 0;
        }
        &::after{
          content: '' !important;
          background: url('../../assets/images/icons/dots.svg');
          background-repeat: no-repeat;
          background-position: center;
          // width: 20px;
          height: 20px;
        }
      }
      .dropdown-menu.show {
        transform: translate3d(-160px, 38px, 0px) !important;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
        border: .8px solid $gray-200;
        padding: 8px;
        width: 12.8rem;
        .edit-dropdown{
          color: $gray-900;
          font-size: 14px;
          &:hover{
            background-color: $gray-100;
          }
          .edit-icon{
            color: $gray-900;
            height: 30px;
            width: 20px;
            margin-right: 10px;
          }
        }
      }
      .delete-dropdown{
        font-size: 14px;
        .delete-icon{
          color: $gray-900;
          height: 30px;
          width: 22px;
          margin-right: 10px;
        }
        &:hover{
          color: $error-600;
          background-color: $error-50;
          .delete-icon{
            color: $error-600;
          }
        }
      }
    }

    &:hover {
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        text-decoration: none;
        color: $dark;
        text-decoration: none !important;

        .application__link{
            text-decoration: underline !important;
        }
      }


    &__image {
      width: 29px;
      height: 29px;
      border-radius: 4px;
      margin-right: 10px;
    }

    &__dropdown {
      .dropdown-toggle {
        padding: 0 4px !important;
        text-decoration: none !important;
        font-size: 14px !important;
      }
      .dropdown-item {
        text-decoration: none !important;
        font-size: 14px !important;
        padding: 4px 16px !important;
        color: black !important;
      }

      a {
        text-decoration: none !important;
        font-size: 14px !important;
        color: black !important;
      }
    }

    &__title {
      color: $dark;
      font-size: $font-size-md;
      font-weight: 600;
      width: 160px;
      &__new{
        color: $dark;
        font-size: $font-size-md;
        font-weight: 600;
        width: auto !important;
      }
    }

    &__subtitle {
      font-size: $font-size-sm;
      max-width: 415px;
      color: $gray-500;
    }

    &__btn {
      font-size: 16px;
    }

    &__label {
      font-size: $font-size-xs;
      color: $gray-400;
      margin-bottom: 5px;
    }

    &__status {
      border-radius: 16px;
      display: flex;
      align-items: center;
      height: 22px;
      padding: 0 8px;
      font-size: $font-size-xs;
      text-transform: capitalize;

      &__indicator {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 7px;
      }

      &--review {
        background: #FFFAEB;
        color: #B54708;

        .application__status__indicator {
          background: #F79009;
        }
      }

      &--rejected {
        background: #FEF3F2;
        color: #B42318;

        .application__status__indicator {
          background: #F04438;
        }
      }

      &--prequalified {
        background: #ECFDF3;
        color: #027A48;

        .application__status__indicator {
          background: #12B76A;
        }
      }

      &--submitted {
        background: #EFF8FF;
        color: #175CD3;

        .application__status__indicator {
          background: #2E90FA;
        }
      }

      &--draft {
        background: $gray-100;
        color: $gray-700;

        .application__status__indicator {
          background: $gray-500;
        }
      }
      &--modification{
        background: #FFFAEB;
        color: #B54708;

        .application__status__indicator {
          background: #F79009;
        }
      }
      &--pending{
        background: #FFFAEB;
        color: #B54708;

        .application__status__indicator {
          background: #F79009;
        }
      }
    }

    &__link {
      color: $primary;
      font-size: $font-size-xs;
      font-weight: 500;
      padding-bottom: 2px;
    }
  }
}