html,
body,
#root,
.silta {
  min-height: 100vh;
  position: relative;
  color: $dark;
}
a{
  text-decoration: none;
}

.silta {
  padding-bottom: $footer-height;
}

@media (min-width: 1400px) {
  .home, .project__banner, .section_title, 
  .section_subtitle, section, .button-nav, 
  .financiers-list, .searchbar__container, 
  .project-list__project, .nav__content, .loan-application-page,
  .project__banner-sm, .loan-applications, .project-page, .financiers-page,
  .project, .financier, .prequal-banner, .loan-application-page__welcome {
   max-width: 1100px !important;
  } 
  .loan-application-page, .loan-application-page__welcome {
    margin: 0 auto;
  }
 
  .button-nav__container {
    margin-right: 40px !important;
  }
}

.loan-application-page__welcome{
  max-width: 900px;
  margin: 0 auto;
}

section {
  padding: 43px 0 65px 0;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.bg-white {
  background-color: white !important;
}

.hover {
  &:hover {
    cursor: pointer !important;
  }
}

.nav {
  border-top: 10px solid $primary;
  border-bottom: 1px solid $gray-200;
  position: relative;
  height: $nav-height;
  &__disclaimer{
    background-color: $gray-25;
    height: fit-content;
    width: 100vw;
    position: absolute;
    top: 0;
    border-bottom: 0.5px solid $gray-300;
    padding: 10px ;
    text-align: center;
    z-index: 9;
    span{
      font-size: $font-size-md;
    }
    &__title{
      color: $gray-900;
      font-weight: 500;
    }
    &__subtitle{
      color: $gray-500;
    }
    &__link{
      color: $primary;
      a:hover {
        color: $primary;
      }
    }
    &__close{
      position: absolute;
      right: 30px;
      top: 8px;
    }
  }
  &__content{
    width: 100%;
    max-width: 900px;
    a:hover{
        text-decoration: none;
      }

    &__active{
      color: black !important;
      padding-bottom: 7px;
      border-bottom: 1px solid black;
    }
  }

  &__logo {
    position: absolute;
    transform: translate(-0%, -50%);
    vertical-align: middle;
  }

  &__toggle{

    &-user{
      position: relative;
    }

    &-dropdown{
      font-size: 14px;
      color: black;
      font-weight: 500;
      position: absolute;
      top: 116%;
      width: 220px;
      padding: 10px 10px;
      border: 1px solid #F2F4F7;
      background-color: white;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
      border-radius: 4px;
      z-index: 9;
      opacity: 0;
      visibility: hidden;
      svg{
        margin-left: 0 !important;
        margin-right: 16px;
        width: 18px;
        height: 18px;
        stroke: black;
      }
      &--toggle{
        opacity: 1;
        transition: opacity .2s ease-in-out;
        visibility: visible;
        &--container{
          padding: 5px 10px;
          &:hover{
            background-color: $gray-100;
          }
        }
      }
    }
  }

  .nav-login-btn{
    color: $primary;
    padding: 8px 14px;
    border: 1px solid $primary;
    border-radius: 20px;
    font-size: 14px;
  }

  .nav-user-btn{
    color: $gray-700;
    font-size: 16px;
    padding: 8px 14px;
    border: 1px solid $gray-300;
    border-radius: 20px;

    svg{
      margin-left: 8px;
    }
    &--toggle{
      .arrow-down{
        transform: rotate(180deg);
        transition: transform .2s ease-in;
      }
    }
    &:hover{
      background-color: $gray-50;
      cursor: pointer;
    }
  }

  .link-nav {
    cursor: pointer;
    font-size: 14px;
    position: relative;
    white-space: nowrap;
    color: $gray-600;
    padding-bottom: 7px;
    margin-right: 20px;
  }

  .link-nav::before,
  .link-nav::after {
      position: absolute;
      width: 100%;
      height: 1px;
      background: black;
      top: 100%;
      left: 0;
      pointer-events: none;
  }

  .link-nav::before {
      content: '';
  }

  .link-nav--animation::before {
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
  }

  .link-nav--animation:hover::before {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
  }
};

h1 {
  font-size: $font-size-xxl;
  font-weight: 600;
}

.text-gray {
  color: $gray-500;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-xxl {
  font-size: $font-size-xxl;
}

.no-decoration {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.subtitle {
  font-size: $font-size-sm;
  color: $gray-500;
  margin-bottom: 23px;
}

.footer {
  color: $gray-400;
  position: absolute;
  bottom: 0;
  right: 1px;
  width: 100%;
  height: $footer-height;
  padding-top: 64px;
  border-top: 1px solid $gray-200;

  &--form {
    width: calc(100% - #{$form-side-width});
  }

  &__socials {
    padding-top: 32px;
    padding-bottom: 64px;
    width: 100%;

    &__link {
      margin: 0 16px;
      font-size: $font-size-sm;
      color: $gray-400;
      &:hover{
        color: $gray-700;
      }
    }
  }

  &__underlined-text{
    text-decoration: underline;
  }

  &__policy {
    padding-top: 32px;
    padding-bottom: 48px;
    font-size: $font-size-sm;
    border-top: 1px solid $gray-200;

    a {
      color: $gray-400;
      margin-left: 16px;
    }
  }
  &__arrow-right-icon{
    width: 1rem;
    height: 1rem;
    margin-left: 4px;
  }
}

.modal-open .conditions-modal{
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.conditions-modal{
  .modal-dialog-centered{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-dialog{
    transform: translate(0, -50px) !important;
  }

  .modal-header, .modal-footer{
    border: none;
  }
  .modal-header{
    display: flex;
    align-items: center;
    padding: 0px 4px 16px 0;
    .h4{
      font-weight: 600 !important;
      font-size: 18px !important;
    }
  }

  .modal-content{
    width: 400px;
    height: 418px;
  }

  .modal-body{
    padding: 0 4px;

    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $gray-600;
      margin-bottom: 0;
    }
  }

  .modal-footer{
    padding: 0 4px;
  }
  &__btn{
    padding: 10px 0;
    background-color: white;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 60px;
    font-size: 16px;
    margin-top: 28px;
  }

  &__close{
    width: 24px;
    height: 24px;
    color: $gray-500;
    &:hover{
      cursor: pointer;
    }
  }
}

.tag {
  text-transform: uppercase;
  color: $primary;
  background: $light;
  padding: 2px 10px;
  border-radius: 16px;
  font-size: $font-size-xs;
  height: 22px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.side-nav {
  width: $side-width;
  height: 100%;
  border-right: 1px solid $gray-200;
  position: absolute;
  background: white;
  left: 0;
  top: 0;

  &__link {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 27px 55px;
    transition: $default-transition;
    padding-right: 0;
    color: $gray-700;
    position: relative;
    font-size: 16px;

    // &-docs{
    //   background-color: transparent;
    //   display: flex;
    //   align-items: center;
    //   font-weight: 500;
    //   padding: 27px 55px;
    //   transition: $default-transition;
    //   padding-right: 0;
    //   color: $gray-700;
    //   position: relative;
    //   font-size: 16px;
    //   border: none;
    // }

    // &:hover {
    //   text-decoration: none;
    //   color: $primary;

    //   .side-nav__link__icon--project {
    //     background: url('../assets/images/project-active.svg') no-repeat center;
    //   }

    //   .side-nav__link__icon--dashboard {
    //     background: url('../assets/images/dashboard-active.svg') no-repeat center;
    //   }

    //   .side-nav__link__icon--application {
    //     background: url('../assets/images/application-active.svg') no-repeat center;
    //   }

    //   .side-nav__link__icon--lending-pools {
    //     background: url('../assets/images/lending-pools-active.svg') no-repeat center;
    //   }

    //   .side-nav__link__icon--project-info {
    //     background: url('../assets/images/icons/proj-information-active.svg') no-repeat center;
    //   }
    //   .side-nav__link__icon--loan-info {
    //     background: url('../assets/images/icons/info-active.svg') no-repeat center;
    //   }
    // }

  }
}

.silta-page {
  width: 100%;
  min-height: calc(100vh - #{$nav-height} - #{$footer-height});
  background: $gray-50;

  &--with-sidebar {
    // padding-left: $side-width + 100px;
    padding-left: $side-width;

    &--form {
      background: #F6F6F6;
      padding: 24px 32px;
      padding-left: $form-side-width + 32px;
    }
  }
  .section_title{
    width: 100%;
    max-width: 900px;
    margin: 0px auto 10px auto
  }
  .section_subtitle{
    width: 100%;
    max-width: 900px;
    margin: 0px auto 40px auto;
    color: $gray-500;
    font-weight: 400;
  }
  .section_black{
    color: black;
    font-weight: 600;
  }
  .breadcrumb-name{
    color: $gray-500;
    .edit-name-btn:hover{
      cursor: pointer;
    }
  }
  .breadcrumb-slash{
    color: #D0D5DD
  }
  @media (min-width: 1400px) {
      &--with-sidebar--form {
        padding-left: 480px;
      }
  }
}

.btn-connect{
  width: 129px;
  height: 36px;
  font-size: 14px;
}

.layout__wallet img{
    width: 20px;
    margin-right: 8px;
  }

 .progress-container{
  position: relative;

 .progress-percentage{
    font-size: $font-size-xs;
    color: $gray-800;
    position: absolute;
    top: 50px;
 }
}


.home{
  width: 100%;
  max-width: 900px;
  margin: 13px auto 36px auto;
  &_title{
    color: black;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 8px;
  }
  &_subtl{
    color: $gray-500;
    font-size: 18px;
    line-height: 28px;
    max-width: 800px;
  }
}

.project__banner_title{
    color: black;
    font-size: 24px;
    font-weight: 600;
    margin-top: 18px;
    margin: 0 auto;
  }
  
.project__banner_subtl{
    color: black;
    font-size: 18px;
    width: 760px;
    margin-bottom: 0px;
    line-height: 28px;
    margin-top: 14px;
  }

.project__banner{
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
  padding: 50px 60px;
  border-radius: 10px;
  background-color: white;
  background-image: url('../assets/images/project-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;

  &-sm{
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    height: 200px;
    padding: 50px 35px;
    border-radius: 4px;
    // background-color: white;
    background-image: url('../assets/images/banner-sm-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);

    .project__banner_title{
      color: black;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0%;
    }

    .project__banner_subtl{
      color: black;
      font-size: 18px;
      width: 800px;
      margin-bottom: 0px;
      line-height: 28px;
      margin-top: 14px;
    }
  }

  .project__banner_title{
    color: black;
    font-size: 24px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 0%;
  }

  .project__banner_subtl{
    color: black;
    font-size: 18px;
    width: 760px;
    margin-bottom: 0px;
    line-height: 28px;
    margin-top: 14px;
  }
  .project__banner_disclaimer{
    margin-top: 35px;
    color: $gray-400;
    font-size: 14px;
    width: 790px;
  }
}