@import "colors";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary
);

// Bootstrap functions
// -------------------------------------
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/variables";

// Custom variables
// -------------------------------------
@import "variables";


// Rest of the bootstrap
// -------------------------------------
@import "~bootstrap/scss/bootstrap";


// General styling
@import "../assets/fonts/fonts.css";
@import "global";
@import "form";
@import "modals";
@import "project-list";
@import "notifications";

// Pages
@import "./pages/project-list";
@import "./pages/auth";
@import "./pages/project";
@import "./pages/redirector";
@import "./pages/loan-application";
@import "./pages/borrower-profile";
@import "./pages/loan-pools";